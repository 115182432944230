.styled-form .row {
  margin: 0;
  width: 100%;
}

@media (max-width: 600px) {
  .styled-form {
    padding: 0 1em;
  }
}
